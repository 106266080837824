import { Component } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { authService } from './services/auth.service';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private storage: Storage,
    private AuthService: authService,
    private router: Router,
  private menuController:MenuController) { }
  public isMenuSetup = 0
  public isMenuGov = 0
  public isMenuSec = 0

  async ngOnInit() {
    await this.storage.create();
    console.log('storage criado');
  }
  async onClose() {
    await this.menuController.toggle().then(async ()=>{
      await this.AuthService.logout().then(()=>{
        this.router.navigateByUrl('/login');
      });
      
    });
    
  }
}
