import { Injectable } from "@angular/core";
//import { Storage } from '@ionic/storage';
import { Storage } from '@ionic/storage';
import { storageModel } from '../model/storageModel';

@Injectable({
  providedIn: 'root'
})
export class authService {

  constructor(private storage: Storage) {


  }

  async logout() {
    await this.storage.remove('USER');

    //localStorage.clear();
  }
  async isLoggedIn() : Promise<boolean> {
    return await this.storage.get('USER')
      .then((mUser: storageModel) => {
        if (mUser == null) {
          return false;
        }
        else {
          return true;
        }
      }).catch((err) => {
        console.log(err);
        return false;
      });

  }
}
