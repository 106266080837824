// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pointer {
  cursor: pointer;
}

ion-icon {
  color: #287374;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAAA;EACI,cAAA;EACA,eAAA;AAGJ","sourcesContent":[".pointer         \n{ cursor: pointer; }\nion-icon{\n    color:#287374;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
