import { Injectable } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { storageModel } from '../model/storageModel';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ModalMensComponent } from '../component/modal-Mens/modal-Mens.component';


@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  constructor(
    public loadingController: LoadingController,
    private modalController: ModalController,
    private storage: Storage,
    private router: Router

  ) { }

  public messageLoading: string = '';

  async crypt(text, par, token) {
    var chave = 'Mariah@Raphaela@Priscila'
    var bl1 = token.length / 2;
    var key = token.slice(0, bl1).toString() + chave + token.slice(bl1, token.length).toString();

    if (par == 'C') {
      return CryptoJS.AES.encrypt(text, key).toString();
    }
    else if (par == 'D') {
      return CryptoJS.AES.decrypt(text, key).toString(CryptoJS.enc.Utf8);
    }
    return '';

  }

  async GetUser(): Promise<storageModel> {
    var user: storageModel;
    await this.storage.get('USER')
      .then(async (_user: storageModel) => {
        user = _user;
      });
    return user;
  }

  showLoader(msn: string = '') {
    this.loadingController.create({
      message: msn,
      spinner: 'lines-small'
    }).then((res) => {
      res.present();
    });

  }

  async hideLoader(msn: string = '') {

    while (await this.loadingController.getTop() !== undefined) {
      await this.loadingController.dismiss();

    }
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async OpenModalMens(pTitulo: string = '',
  pL: string[] = [],
  pTipo: string = 'M',
  pIcone: string = '',
  pExec:Object ={}) {

  const modal = await this.modalController.create({
    component: ModalMensComponent,
    cssClass: 'classModalEm',
    swipeToClose: true,
    animated: true,
    componentProps: {
      "Titulo": pTitulo,
      "Linha": pL,
      "Tipo": pTipo,
      "Icone": pIcone,
      "Exec":pExec
    }
  });
  await modal.present();
  return modal;
}

}
