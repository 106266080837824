import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { IonicModule } from '@ionic/angular';
import { ModalMensComponent } from './modal-Mens.component';




@NgModule({
  imports: [ CommonModule,  IonicModule],
  declarations: [ModalMensComponent],
  exports: [ModalMensComponent]
})
export class ModalMensModule {}