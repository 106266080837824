import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadingController, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { storageModel } from '../model/storageModel';
import { authService } from './auth.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HubService {


  constructor( private httpClient: HttpClient,
               private storage:Storage,
               private AuthService:authService,
               private router:Router){}

  async  hub(pVerbo:string , pJson:object , ptoken:string = '') {
    let data ;
    let url = environment.Portal.apihub + '/'+pVerbo.trim();
    await this.storage.get('USER')
    .then(async (_user: storageModel)=>{  
       data = await this.postRequest2(url,pJson, _user.token );
       })
       .catch(async ()=>{
         // No caso de não estar logado ... faz uma tentativa sem o token.
        data = await this.postRequest2(url,pJson, '' );
       });

   // let data = await this.postRequest2(url,pJson, ptoken);
    return data;
  }

  private async postRequest2(purl: string, pbody: object, ptoken: string): Promise<any> {
    let result;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'responseType': 'text',
        'rejectUnauthorized': 'false',  //DevSkim: ignore DS125134 
        'Authorization': 'Bearer ' + ptoken
      })
    };
    await this.httpClient.post<any>(
      `${purl}`
      , pbody
      , httpOptions
    ).toPromise().then((res: any) => {
      result = res;
      return res;
    }).catch(async (err: HttpErrorResponse) => {
      console.log('erro : ' + err.message);
      result = Object({ message: 'erro' });
      await this.AuthService.logout()
      .then(()=>{
        this.router.navigateByUrl('/login');
      });
    });

    return result;
  }


}
