import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { HubService } from 'src/app/services/hub.service';
import { RepositoryService } from 'src/app/services/respository.service';


@Component({
  selector: 'app-modal-Mens',
  templateUrl: './modal-Mens.component.html',
  styleUrls: ['./modal-Mens.component.scss'],
})
export class ModalMensComponent implements OnInit {
  resp = ''
  oMens: {
    Titulo: string,
    L: string[],
    Icone: string,
    Tipo: string,
    Exec: Object
  }
    = Object({
      Titulo: '',
      L: {},
      Icone: '',
      Tipo: '',
      Exec: {}
    });

  fileName = '';
  fileUrl;

  constructor(public modalController: ModalController,
    public router: Router,
    private navParams: NavParams,
    private hubService: HubService,
    private sanitizer: DomSanitizer,
    private repositoryService: RepositoryService) {
    console.log('####### - >  contrutor');
  }

  ngOnInit() {
    this.fileName = '';
    this.oMens.Titulo = this.navParams.get("Titulo");
    this.oMens.L = this.navParams.get("Linha");
    this.oMens.Icone = this.navParams.get("Icone");
    this.oMens.Tipo = this.navParams.get("Tipo");
    if (this.oMens.Tipo == "SN" || this.oMens.Tipo == "DOWNLOAD" || this.oMens.Tipo == "DOWNLOADPLACA") {
      this.oMens.Exec = this.navParams.get("Exec");
    }
  }
 
  async onClickSim(pExec) {
    var postData = pExec.postData;
    var command = pExec.Command;
    var Result
    console.log('SIM------');
    console.log(pExec.postData);
    console.log(pExec.Command);
    await this.hubService.hub(command, postData)
      .then(async (data) => {
        Result = JSON.parse(JSON.stringify(data));
        console.log('Resultado:');
        console.log(Result);
      
        await this.closeModal('S');
      });
  }

  async onClickUpLoadDoc(pExec) {
    this.repositoryService.showLoader('Downloading...');
    let postData1 = {
      '@id_transportadora': pExec.linha['id_transportadora'],
      '@id_Doc': pExec.linha['Id']
    };
    let command = 'GET_TRASPDOCBYID';
    await this.hubService.hub(command, postData1)
      .then(async (data) => {
        console.log(data);
        let v = await data[0].blob;
        console.log(v);
        console.log('Arquivo recebido');
        //blob = new Blob([new Uint8Array((v as ArrayBuffer))],{ type: data[0].fileType });
        const byteCharacters = atob(v.replace(/^[^,]+,/, '')); //.split('');
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: data[0].tp_arquivo });
        //      var file = new File([v], 'teste.pdf', {type:data[0].tp_arquivo });

        //blob = new Blob([v],{ type: data[0].tp_arquivo });
        // blob = base64StringToBlob(v);
        const blobURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        // blobURL = createObjectURL(blob);
        this.fileUrl = blobURL;
        this.fileName = pExec.linha['Arquivo'];
        //console.log(blobURL);
      }).finally(async () => {
        await this.repositoryService.delay(500);
        this.repositoryService.hideLoader();
      });
  }

  async onClickApagaDocPlaca(pExec) {
    this.repositoryService.showLoader(`Apagando ${pExec.postData['@tp_doc']}...`);
    let postData1 = {
      '@id_transportadora': pExec.postData['@id_transportadora'],
      '@id_placa': pExec.postData['@id_placa'],
      '@tp_doc': pExec.postData['@tp_doc'],
    };

    let command = 'APAGA_DOCPLACA';
    console.log(command);
    await this.hubService.hub(command, postData1)
      .then(async (data) => {
      }).finally(async () => {
        await this.repositoryService.delay(500);
        this.repositoryService.hideLoader();
        await this.closeModal();
      });
  }

  async onClickUpLoadDocPlaca(pExec) {
    console.log(pExec);
    this.repositoryService.showLoader(`Downloading ${pExec.postData['tp_doc']}...`);
    let postData1 = {
      '@id_transportadora': pExec.postData['@id_transportadora'],
      '@id_placa': pExec.postData['@id_placa'],
      '@tp_doc': pExec.postData['@tp_doc'],
    };

    let command = 'GET_DOCPLACABYID';
    await this.hubService.hub(command, postData1)
      .then(async (data) => {
        console.log(data);
        let v = await data[0].blob;
        console.log(v);
        console.log('Arquivo recebido');
        //blob = new Blob([new Uint8Array((v as ArrayBuffer))],{ type: data[0].fileType });
        const byteCharacters = atob(v.replace(/^[^,]+,/, '')); //.split('');
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: data[0].tp_arquivo });
        //      var file = new File([v], 'teste.pdf', {type:data[0].tp_arquivo });

        //blob = new Blob([v],{ type: data[0].tp_arquivo });
        // blob = base64StringToBlob(v);
        const blobURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        // blobURL = createObjectURL(blob);
        this.fileUrl = blobURL;
        this.fileName = data[0].nm_arquivo;
        //console.log(blobURL);
      }).finally(async () => {
        await this.repositoryService.delay(500);
        this.repositoryService.hideLoader();
      });
  }

  async closeModal(Resp='') {
    
    let onClosedData: string = "Wrapped Up!";
    if(this.oMens.Tipo == 'SN'){
      onClosedData = Resp;
    }
    await this.modalController.dismiss(onClosedData).then((x) => {
      //this.router.navigate(['/inicio']);
    });

  }

}
