import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'pageDS',
    loadChildren: () => import('./page/page-DS/page-DS.module').then( m => m.pageDSModule)
  },
  {
    path: 'pageusuPortal',
    loadChildren: () => import('./page/page-usuPortal/page-usuPortal.module').then( m => m.pageusuPortalModule)
  },
  {
    path: 'pageCliente',
    loadChildren: () => import('./page/page-Cliente/page-Cliente.module').then( m => m.pageClienteModule)
  },
  {
    path: 'pageRegrasSec',
    loadChildren: () => import('./page/page-RegrasSec/page-RegrasSec.module').then( m => m.pageRegrasSecModule)
  },
  {
    path: 'pagePerfilSec',
    loadChildren: () => import('./page/page-PerfilSec/page-PerfilSec.module').then( m => m.pagePerfilSecModule)
  },
  {
    path: 'pageperfilCliente',
    loadChildren: () => import('./page/page-perfilCliente/page-perfilCliente.module').then( m => m.pageperfilClienteModule)
  }
  ,
  {
    path: 'pageInstancia',
    loadChildren: () => import('./page/page-Instancia/page-Instancia.module').then( m => m.pageInstanciaModule)
  },
    {
    path: 'pageDiagramClientePerfilPoint',
    loadChildren: () => import('./page/page-DiagramCliente-Perfil-Point/page-DiagramCliente-Perfil-Point.module').then( m => m.pageDiagramClientePerfilPointModule)
  },
  {
  path: 'mLogin',
  loadChildren: () => import('./mlogin/mlogin.module').then( m => m.mLoginPageModule)
}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
